@import '../../node_modules/normalize.css/normalize.css';
@import '../../node_modules/react-toastify/dist/ReactToastify.css';

html {
  min-height: 100%;
  font-family: usual, Helvetica, sans-serif;
  letter-spacing: -0.4px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #484848;
  background-color: #F5F5F5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  overflow-x: hidden;
  background-color: #F5F5F5;
  color: #484848;
  font-family: usual, Helvetica, sans-serif;
  font-weight: 300;
  letter-spacing: -0.4px;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";

  &.loggedin {
    background-color: #FFFFFF;
  }
  &.locked {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}
fieldset {
  padding: 0;
  border: 0;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 32px 10px 0;
  border: 0;
  font-size: 1em;
  background-size: 12px;
  vertical-align: middle;

  option {
    font-size: 1em;
  }
  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: 0;
  }
}
button {
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: 0;
  }
}

.reactour__helper {
  max-width: 400px !important;

  i {
    font-weight: 600;
  }
  > button {
    top: 12px; right: 12px;
    width: 12px; height: 12px;
    will-change: color;
    transition: color .3s ease-out;
  }
}

.only-date-picker::-webkit-datetime-edit-fields-wrapper { pointer-events: none; }
.only-date-picker::-webkit-calendar-picker-indicator { cursor: pointer; }

.only-date-picker {
  outline: none;
}
